@import '../theme/base';

.admin-navigation {
  border-bottom: 1px solid color('base-lighter');
  margin-bottom: 2rem;
}

.admin-navigation__container {
  position: relative;
}

.admin-navigation__name,
.admin-navigation__logout {
  display: block;
  margin: 1rem 0 0.25rem;
}

.admin-navigation__link {
  @include h2;
  border-bottom: 0.25rem solid transparent;
  color: color('base-light');
  display: block;
  font-weight: normal;
  padding: 1rem 0 0.75rem;
  text-align: center;
  text-decoration: none;
  width: 12rem;
  &:focus {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0.25rem;
  }
}

.admin-navigation__link-icon {
  position: relative;
  top: 4px;
  height: 24px;
  width: 24px;
}

.admin-navigation__link--active,
.admin-navigation__link--active:focus {
  border-bottom: 0.25rem solid color('primary');
  color: color('primary');
  font-weight: bold;
}

.admin-navigation__alert {
  position: absolute;
  width: 50%;
  left: 25%;
}
