@import '../theme/base';

.header {
  @include u-padding(2);
  background-color: white;
  height: units(15);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.1);
}

.header__logo {
  height: units(2);
  user-select: none;
}

.header__logout {
  top: 0;
  right: units(2);
  @include u-padding-x(0);
  @include u-padding-y(1);
  position: absolute;

  & a,
  a:visited,
  a:hover,
  a:active {
    color: $theme-color-primary;
  }
}

.header__name {
  margin: 0;
  padding: 1rem 1.5rem 1.5rem;
  text-align: center;
}

.header__time {
  margin: 0;
  padding: 0.5rem 1.5rem 0;
  text-align: right;
  font-feature-settings: 'tnum' 1;
}
