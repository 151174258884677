@import '../theme/base';

.counter {
  align-items: center;
  border: 1px solid color('base-lighter');
  border-radius: units(0.5);
  display: flex;
  justify-content: space-between;
  margin-bottom: units(2);
  padding: units(1) units(2);
}

.counter__label {
  font-weight: bold;
}

.counter__control {
  align-items: center;
  display: flex;
}

.counter__input {
  text-align: center;
  border-color: transparent;
  max-width: units(6) !important;
  @include u-margin-top(0);
  &:disabled,
  &:read-only {
    color: color('base-darkest');
    opacity: 1;
  }
  // hide browser default numeric spin controls
  -moz-appearance: textfield; /* Firefox */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.counter__button {
  align-items: center;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  font-size: units(3);
  height: units(3);
  justify-content: center;
  margin: 0 units(1) !important;
  padding: 0;
  width: units(3);
  visibility: hidden;
}

.counter--editing {
  border-color: color('base-dark');
  .counter__button {
    visibility: visible;
  }
  .counter__input {
    border-color: inherit;
    background-color: white !important;
  }
}
