@import '../theme/base';

.status-list {
  list-style-type: none;
  padding: 0;
}

.status-list-item {
  @include h3;
  align-items: center;
  color: color('base-light');
  display: flex;
  height: 4.5rem;
}

.status-list-item__icon {
  height: 4.5rem;
  position: relative;
  width: 2.875rem;
}

.status-list-item__icon::before {
  background-color: color('base-lighter');
  content: ' ';
  display: block;
  height: 100%;
  left: 1.125rem;
  position: absolute;
  top: 0;
  width: 2px;
}

.status-list-item__icon::after {
  background-color: white;
  border: 2px solid color('base-lighter');
  border-radius: 50%;
  content: ' ';
  display: block;
  height: 1.375rem;
  left: 0.5rem;
  position: absolute;
  top: 1.5625rem;
  width: 1.375rem;
}

.status-list-item__text {
  flex-grow: 1;

  .timestamp__time {
    @include h4;
    color: color('base-light');
    display: block;
    position: absolute;
  }
}

.status-list-item:first-child .status-list-item__icon::before {
  top: 50%;
  height: 50%;
}

.status-list-item:last-child .status-list-item__icon::before {
  height: 50%;
}

.status-list-item--noninteractive .status-list-item__icon::after {
  background-color: color('base-lighter');
  height: 0.75rem;
  left: 0.8125rem;
  top: 1.875rem;
  width: 0.75rem;
}

.status-list-item--completed .status-list-item__icon::before {
  background-color: color('primary');
}

.status-list-item--completed {
  color: color('base-darkest');
}

.status-list-item--completed .status-list-item__icon::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  background-color: color('primary');
  border-color: color('primary');
  color: white;
  content: '\f00c';
  display: flex;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 0.875rem;
  font-style: normal;
  font-variant: normal;
  justify-content: center;
  line-height: 1;
  text-rendering: auto;
}
