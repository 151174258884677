@import '../theme/base';

.no-scroll {
  height: var(--vh);
  overflow: hidden;
}

.unconfirmed-ringdowns {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(27, 27, 27, 0.5);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  height: var(--vh);
}

.unconfirmed-ringdowns__container {
  background-color: white;
  border-radius: 1rem 1rem 0 0;
  // the Header is 7.5rem high, so leave a little space between it and the ringdown container
  max-height: calc(var(--vh) - 8rem);
  padding: 1.25rem;
  // let the overlay scroll vertically, since the body won't scroll while it's open
  overflow-y: auto;

  h2 {
    text-align: center;
  }

  .ringdown-card {
    margin: 1rem 0;

    &:first-child {
      border: 3px solid color('warning');
    }
    &:not(:first-child) {
      pointer-events: none;
      .drawer__prompt {
        color: color('base-light');
      }
    }
  }
}

.unconfirmed-ringdowns__confirm {
  padding: 0.625rem;
}

.unconfirmed-ringdowns__more {
  color: color('base');
}
