@import '../theme/base';

.tabbar {
  @include u-margin-top(2);
  background: color('gray-10');
  border-radius: 12px;
  display: flex;
}

.tabbar__tab {
  @include u-margin(0.5);
  @include u-padding-x(1);
  @include u-padding-y(0.5);
  align-items: center;
  background: none;
  border: none;
  border-radius: 8px;
  color: color('gray-50');
  cursor: pointer;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  height: units(5);
  justify-content: left;
}

.tabbar__tab--selected {
  background-color: white;
  color: color('primary');
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.tabbar__icon {
  @include u-margin-right(0.5);
}

.tabbar-content {
  z-index: 0;
  display: none;
}

.tabbar-content--selected {
  display: block;
}
