@import '../theme/base';

$rotation-deg: 60deg;

@mixin rotate {
  transform: rotate($rotation-deg);
  transform-origin: center right;
  -webkit-transform: rotate($rotation-deg);
  -moz-transform: rotate($rotation-deg);
  -ms-transform: rotate($rotation-deg);
  -o-transform: rotate($rotation-deg);
}

.hospitalstatusheader__column-name {
  @include u-text('base-light');
  @include u-text('no-wrap');
  @include h4;
  position: absolute;
  top: 0;
  right: 50%;
  margin: 0;
  @include rotate;
}

.hospitalstatusheader__column-container {
  width: 33%;
  height: 2em;
  position: relative;
}

.hospitalstatusheader__container {
  height: 150px;
}
