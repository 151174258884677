@import '../../theme/base';

.mci-row {
  border: 2px solid color('base-lighter');
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.mci-row__header,
.mci-row__status,
.mci-row__transported {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
}

.mci-row__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  flex-shrink: 1;
}

.mci-row__header {
  background-color: color($theme-color-base-lightest);
  color: color('base-dark');
}

.mci-row__header--alert {
  background-color: color($theme-color-warning-lighter);
}

.mci-row__transported {
  border-top: 1px solid color('base-lighter');
}

.mci-row__info {
  flex-shrink: 0;
  width: 25%;
}

.mci-row__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  text-wrap: nowrap;
  a {
    text-decoration: none;
  }
  span {
    @include h4;
  }
}

.mci-row__updated {
  color: color('base-dark');
}
