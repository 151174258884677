@import '../theme/base';

.beds .heading {
  border-bottom: 0;
}

.beds__counter {
  border-width: 2px;
}

.beds__counter--immediate {
  background-color: color($mci-color-immediate-lightest);
  border-color: color($mci-color-immediate);
  .counter__input {
    background-color: color($mci-color-immediate-lightest);
  }
  .counter__button {
    background-color: color($mci-color-immediate);
    &:hover {
      background-color: color($mci-color-immediate-darker);
    }
  }
}

.beds__counter--delayed {
  background-color: color($mci-color-delayed-lightest);
  border-color: color($mci-color-delayed-darker);
  .counter__input {
    background-color: color($mci-color-delayed-lightest);
  }
  .counter__button {
    background-color: color($mci-color-delayed-darker);
    &:hover {
      background-color: color($mci-color-delayed-darkest);
    }
  }
}

.beds__counter--minor {
  background-color: color($mci-color-minor-lightest);
  border-color: color($mci-color-minor);
  .counter__input {
    background-color: color($mci-color-minor-lightest);
  }
  .counter__button {
    background-color: color($mci-color-minor);
    &:hover {
      background-color: color($mci-color-minor-darker);
    }
  }
}

.beds__incoming {
  font-style: normal !important;
  color: color('error') !important;
}

.beds__availability {
  padding: units(1.5) units(3) 0;
}

.beds__change-status {
  @include h4;
  display: block;
}

.beds__diversion {
  @include h3;
  background: white;
  border-left: 4px solid color('primary');
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
  padding: 0.5625rem 1rem 0.5625rem 0.5625rem;
}

.beds__diversion--on {
  border-left-color: color('warning');
}

.beds__notes {
  padding-top: units(0.5);
}

.beds__notes-controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: units(1.5);

  .usa-button {
    margin: 0;
    width: 33%;
  }
}

.beds__updated {
  @include h4;
  color: color('base-light');
  font-style: italic;
  margin-top: units(2);
  text-align: center;
}

.hide-diversion-status {
  display: none;
}
