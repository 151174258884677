@import '../theme/base';

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading__badge {
  @include h4;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  background-color: color('primary');
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
