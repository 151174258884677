@import '../theme/base';

.drawer__content {
  display: none;
  border-top: 1px solid color('base');
}

.drawer__handle {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.75rem;

  &:focus {
    border: inherit;
    outline: inherit;
  }
}

.drawer__prompt {
  @include h4;
  color: color('primary-vivid');
  font-weight: 600;
}

.drawer--opened {
  .drawer__content {
    display: block;
  }
}
