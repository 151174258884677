@import '../theme/base';

$row-height: 6rem;

.hospitalstatusrow__name-container {
  border-bottom: 1px solid color('base-lighter');
  border-left: 1px solid color('base-lighter');
  border-right: 1px solid color('base-lighter');
  height: $row-height;
  position: relative;
}

.hospitalstatusrow__info-container {
  border-bottom: 1px solid color('base-lighter');
  border-right: 1px solid color('base-lighter');
  height: $row-height;
  position: relative;
}

.grid-row:first-child > .hospitalstatusrow__name-container,
.grid-row:first-child > .hospitalstatusrow__info-container {
  border-top: 1px solid color('base-lighter');
}

.hospitalstatusrow__name {
  @include u-margin-left(1);
  @include u-margin-right(0);
  @include u-margin-top(1);
  @include u-margin-bottom(0);
  @include u-padding(0);
  @include h4-bold;
  justify-content: left;
}

.hospitalstatusrow__timestamp {
  @include u-margin-left(1);
  @include u-margin-bottom(1);
  @include u-text('base-light');
  @include h4;
  justify-content: left;
  position: absolute;
  bottom: 0;
}

.hospitalstatusrow__data-container {
  @include u-padding-top(1);
  display: flex;
}

.hide-diversion-column {
  display: none;
}

.hospitalstatusrow__data {
  @include h4;
  width: 33%;
  text-align: center;
}

.hospitalstatusrow__data.text-bold {
  font-weight: bold;
}

.hospitalstatusrow__notes {
  @include u-margin-left(2);
  @include u-margin-bottom(1);
  @include u-text('secondary');
  @include u-text('italic');
  position: absolute;
  bottom: 0;
}
