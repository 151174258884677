@import '../theme/base';

.alert {
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .usa-button-group {
    @include u-margin-top(6);
    @include u-padding-x(2);
    @include u-padding-y(0);

    .usa-button-group__item {
      @include u-margin-bottom(2);
    }

    // the USWDS style zeroes out the right margin on the last button, which makes sense for a row
    // of buttons, but not a stack.  so restore the right margin and reduce the bottom one.
    .usa-button-group__item:last-child {
      @include u-margin-right(0.5);
      @include u-margin-bottom(0.5);
    }

    // the custom styles file sets a 2rem top margin on buttons in a form, so reset that in case the
    // alert is rendered inside a form
    .usa-button {
      margin-top: unset;
    }
  }
}

.alert__content {
  max-width: 400px;
  margin: auto;
  overflow-wrap: anywhere;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
