@import '../theme/base';

.multi-field {
  display: flex;
  gap: 0.6rem;
  position: relative;
}

.multi-field__input {
  .grid-row {
    flex-wrap: nowrap;
  }

  .usa-input {
    width: 13ex;
  }

  .usa-error-message {
    top: 3rem;
    max-width: 9rem;
  }
}
