.invite-form__table {
  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  .usa-button {
    margin-top: 0.5rem;
  }
}
